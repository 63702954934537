import { Injectable } from "@angular/core";

import
{
  Observable, catchError
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "../shared/services/api.service";

import {
  ErrorHandlerCreator, ResponseErrorHandler
} from "../shared/services/response-error-handler.service";

import { Calendar } from "./calendar";

@Injectable({
  providedIn : "root"
})
export class CalendarService
{
  private static readonly url = "Calendar";

  private handleError: ErrorHandlerCreator;

  constructor(protected readonly api: APIService,
    readonly responseErrorHandler: ResponseErrorHandler
  )
{
    this.handleError = responseErrorHandler.createErrorHandler("CalendarService");
  }

  getCalendarByEmployeeIdAndDate(
    employeeId: number,
    date: Date
  ): Observable<Calendar[]>
{
    return this.api.get<Calendar[]>(
      `${CalendarService.url}/${employeeId}/${new Date(date).toDateString()}`
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getCalendarByEmployeeIdAndDate", new Array<Calendar>()))
    );
  }

  addNewCalendar(newItem: Calendar):Observable<Calendar | null>
  {
    return this.api.post<Calendar>(CalendarService.url, newItem).pipe(
      unwrapResponse(),
      catchError(this.handleError("addNewCalendar", null))
    );
  }
}